import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Manage } from './deals/manage';
import { Role } from '../components/enums/role.enum';
import { useSessionUser } from '../components/hooks/session/useSessionUser';
import NotFoundErrorPage from './error/NotFoundErrorPage';
import { Container, Stack, Typography } from '@mui/material';
import Search from './deals/search';
import Watchlist from './deals/watchlist';
import Notifications from './notifications/notifications';
import Messages from './messages/messages';
import Archived from './deals/archived';
import Approvals from './approvals/approvals';
import AccountSettings from './settings/account';
import OrganizationSettings from './settings/organization';
import CreateDeal from './deals/deal/create';
import ViewDeal from './deals/deal/view';
import EditDeal from './deals/deal/edit';
import ViewOrganization from './organizations/organization/view';
import Billing from './settings/billing';
import { useUser } from '../components/hooks/users/use-users';
import DealPerformance from './deals/deal/performance';

export const Main = () => {
  const location = useLocation();
  const { sessionUser } = useSessionUser();
  const { data: user } = useUser({ id: sessionUser?.id });
  const isSysAdmin = sessionUser?.role === Role.SysAdmin;
  const isOrgAdmin = sessionUser?.role === Role.OrgAdmin;
  const isInvestor = sessionUser?.role === Role.Investor;

  const isPendingApproval = user?.approvedAt === null;

  useEffect(() => {
    Sentry.setUser({
      id: sessionUser?.id,
      email: sessionUser?.email,
    });
  }, []);

  if (
    isInvestor &&
    location.pathname !== '/settings/account' &&
    isPendingApproval
  ) {
    return (
      <Container maxWidth="xl">
        <Stack alignItems={'center'} spacing={3}>
          <Typography variant="h4">
            Investor account approval pending
          </Typography>
          <Typography variant="body1">
            Our team will activate your account shortly. You will receive an
            email confirmation when complete.
          </Typography>
          <img
            src="/images/stock/business_deal.png"
            style={{
              width: '50%',
              height: '50%',
              borderRadius: 10,
            }}
          />
        </Stack>
      </Container>
    );
  }

  return (
    <Routes>
      {isSysAdmin && <Route path="/approvals" element={<Approvals />} />}
      {(isSysAdmin || isOrgAdmin) && (
        <>
          <Route exact path="/deals" element={<Manage />} />
          <Route exact path="/deals/archived" element={<Archived />} />
          <Route exact path="/deals/create" element={<CreateDeal />} />
          <Route exact path="/deals/:id/edit" element={<EditDeal />} />
        </>
      )}
      <Route exact path="/search" element={<Search />} />
      <Route exact path="/deals/:id" element={<ViewDeal />} />
      <Route
        exact
        path="/deals/:id/performance"
        element={<DealPerformance />}
      />
      <Route exact path="/organizations/:id" element={<ViewOrganization />} />
      {isInvestor && <Route path="/watchlist" element={<Watchlist />} />}
      {!isSysAdmin && (
        <Route path="/notifications" element={<Notifications />} />
      )}
      {!isSysAdmin && <Route path="/messages" element={<Messages />} />}
      <Route path="/settings/account" element={<AccountSettings />} />
      {(isSysAdmin || isOrgAdmin) && (
        <>
          <Route path="/subscriptions" element={<Billing />} />
          <Route
            path="/settings/organization"
            element={<OrganizationSettings />}
          />
        </>
      )}
      {!isSysAdmin && <Route path="/messages" element={<div>Messages</div>} />}
      <Route
        path={'/'}
        element={
          <Navigate
            to={isSysAdmin ? '/approvals' : isOrgAdmin ? '/deals' : '/search'}
          />
        }
        replace
      />
      <Route path="*" element={<NotFoundErrorPage />} />
    </Routes>
  );
};
