import { Button, Stack, Typography } from '@mui/material';
import { Reply } from '@mui/icons-material';
import { MessageThreadListItem } from '../../messages/message-thread-list-item';
import { useNavigate } from 'react-router-dom';
import { sortByCreatedAt } from '../../../helpers/messages-sort';
import { useSessionUser } from '../../hooks/session/useSessionUser';
import { useSnackbar } from '../../hooks/util/use-snackbar';
import { Role } from '../../enums/role.enum';

export const DealThreads = ({ deal }) => {
  const navigate = useNavigate();
  const { sessionUser } = useSessionUser();

  const isSysAdmin = sessionUser?.role === Role.SysAdmin;

  const handleThreadClick = (threadId) => {
    navigate('/messages?tid=' + threadId);
  };

  return (
    <>
      {deal?.threads?.length > 0 ? (
        deal?.threads
          .sort(sortByCreatedAt)
          .slice(0, 5)
          .map((thread, index) => {
            const goToReply = (e) => {
              handleThreadClick(thread.externalId);
            };

            return (
              <div
                key={index}
                style={{
                  marginBottom: '10px',
                  borderBottom: '1px solid lightgrey',
                  paddingBottom: '10px',
                }}
              >
                <MessageThreadListItem
                  thread={thread}
                  deal={deal}
                  organization={deal.organization}
                  onClick={!isSysAdmin ? goToReply : undefined}
                />
                {!isSysAdmin && (
                  <Stack direction={'row'} justifyContent={'flex-end'}>
                    <Button
                      onClick={goToReply}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      startIcon={
                        <Reply
                          fontSize="small"
                          style={{ marginRight: '5px' }}
                        />
                      }
                    >
                      Reply
                    </Button>
                  </Stack>
                )}
              </div>
            );
          })
      ) : (
        <Typography variant="body2" color="textSecondary">
          No messages for this deal yet.
        </Typography>
      )}
    </>
  );
};
