import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const DealKpis = ({ deal }) => {
  const navigate = useNavigate();

  const onClickPageViews = () => {
    navigate('/deals/' + deal.id + '/performance?tab=views');
  };
  const onClickWatchLists = () => {
    navigate('/deals/' + deal.id + '/performance?tab=watchlist');
  };

  return (
    <Stack
      direction={'row'}
      spacing={1}
      justifyContent={'flex-start'}
      sx={{ overflow: 'auto' }}
    >
      <Stack sx={{ width: '150px' }}>
        <Typography color="textSecondary" gutterBottom>
          Page Views
        </Typography>
        <Typography
          variant="h4"
          component={'a'}
          onClick={onClickPageViews}
          sx={{
            textDecoration: 'none',
            color: 'inherit',
            cursor: 'pointer',
            position: 'relative',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          {deal.metrics?.views || 0}
        </Typography>
      </Stack>
      <Stack sx={{ width: '150px' }}>
        <Typography color="textSecondary" gutterBottom>
          Watchlisted
        </Typography>
        <Typography
          variant="h4"
          component={'a'}
          onClick={onClickWatchLists}
          sx={{
            textDecoration: 'none',
            color: 'inherit',
            cursor: 'pointer',
            position: 'relative',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          {deal.metrics?.watching || 0}
        </Typography>
      </Stack>
      <Stack sx={{ width: '150px' }}>
        <Typography color="textSecondary" gutterBottom>
          Contacts
        </Typography>
        <Typography variant="h4">{deal.threads?.length || 0}</Typography>
      </Stack>
    </Stack>
  );
};
