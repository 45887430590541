import {
  Button,
  Container,
  IconButton,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import React from 'react';
import { Role } from '../../../components/enums/role.enum';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSessionUser } from '../../../components/hooks/session/useSessionUser';
import { useSnackbar } from '../../../components/hooks/util/use-snackbar';
import ArrowBack from '@mui/icons-material/ArrowBack';
import {
  useDealViews,
  useDealWatchlists,
} from '../../../components/hooks/deals/use-deal-view';
import { LinkedIn } from '@mui/icons-material';
import { localTime, smartDateFormat } from '../../../helpers/dates';

const PerformanceTab = {
  Views: 'views',
  Watchlist: 'watchlist',
  Contacts: 'contacts',
};

export default function DealPerformance() {
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get('tab') || PerformanceTab.Views;

  const navigate = useNavigate();
  const { sessionUser } = useSessionUser();
  const { render: renderSnackbar, setSnackbar } = useSnackbar();

  const isSysAdmin = sessionUser?.role === Role.SysAdmin;
  const isOrgAdmin = sessionUser?.role === Role.OrgAdmin;
  const isInvestor = sessionUser?.role === Role.Investor;

  const { data: pageViews, isLoading: isLoadingViews } = useDealViews({ id });

  const { data: watchlists, isLoading: isLoadingWatchlists } =
    useDealWatchlists({ id });

  const isLoading =
    (activeTab === PerformanceTab.Views && isLoadingViews) ||
    (activeTab === PerformanceTab.Watchlist && isLoadingWatchlists);

  const handleTabChange = (event, newTab) => {
    const toUrl = '?tab=' + newTab;
    navigate(toUrl);
  };

  const handleGoBack = (event) => {
    navigate('/deals/' + id);
  };

  console.log(pageViews);

  return (
    <Container maxWidth="lg">
      {renderSnackbar()}
      <Stack direction="row" sx={{ mb: 2 }} justifyContent="space-between">
        <Button
          size={'small'}
          sx={{ ml: -1 }}
          onClick={handleGoBack}
          startIcon={<ArrowBack />}
        >
          Go Back
        </Button>
      </Stack>
      <Typography variant="h4">Deal Performance</Typography>
      <Stack spacing={3} sx={{ mt: 1 }}>
        {isLoading ? (
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          <>
            <Tabs value={activeTab} onChange={handleTabChange}>
              <Tab label={`Page Views`} value={PerformanceTab.Views} />
              <Tab label={'Watchlisted'} value={PerformanceTab.Watchlist} />
            </Tabs>
            {activeTab === PerformanceTab.Views ? (
              <Stack>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Investor</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Phone</TableCell>
                      <TableCell>Viewed</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pageViews?.map(({ id, user, viewedAt, viewsCount }) => {
                      const onClickLinkedIn = () => {
                        window.open(
                          user.linkedIn,
                          '_blank',
                          'noopener,noreferrer',
                        );
                      };

                      return (
                        <TableRow key={`${user.id}-${viewedAt}`}>
                          <TableCell>
                            <Stack
                              alignItems="center"
                              direction="row"
                              spacing={2}
                            >
                              <Typography variant="subtitle2">
                                {user?.name ?? 'Anonymous'}
                              </Typography>
                              {user?.linkedIn && (
                                <IconButton
                                  color={'info'}
                                  onClick={onClickLinkedIn}
                                >
                                  <LinkedIn />
                                </IconButton>
                              )}
                            </Stack>
                          </TableCell>
                          <TableCell>
                            {user?.qualification?.category ?? 'Not Selected'}
                          </TableCell>
                          <TableCell>{user?.email}</TableCell>
                          <TableCell>{user?.phone}</TableCell>
                          <TableCell>
                            {viewsCount} x{' '}
                            {smartDateFormat(
                              localTime(viewedAt),
                              'MMM d @ h:mm a',
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Stack>
            ) : activeTab === PerformanceTab.Watchlist ? (
              <Stack>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Investor</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Phone</TableCell>
                      <TableCell>Added On</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {watchlists?.map(
                      ({ userId, dealId, viewsCount, user, updatedAt }) => {
                        const onClickLinkedIn = () => {
                          window.open(
                            user.linkedIn,
                            '_blank',
                            'noopener,noreferrer',
                          );
                        };

                        return (
                          <TableRow key={`${userId}-${dealId}`}>
                            <TableCell>
                              <Stack
                                alignItems="center"
                                direction="row"
                                spacing={2}
                              >
                                <Typography variant="subtitle2">
                                  {user?.name ?? 'Anonymous'}
                                </Typography>
                                {user?.linkedIn && (
                                  <IconButton
                                    color={'info'}
                                    onClick={onClickLinkedIn}
                                  >
                                    <LinkedIn />
                                  </IconButton>
                                )}
                              </Stack>
                            </TableCell>
                            <TableCell>
                              {user?.qualification?.category ?? 'Not Selected'}
                            </TableCell>
                            <TableCell>{user?.email}</TableCell>
                            <TableCell>{user?.phone}</TableCell>
                            <TableCell>
                              {smartDateFormat(
                                localTime(updatedAt),
                                'MMM d @ h:mm a',
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      },
                    )}
                  </TableBody>
                </Table>
              </Stack>
            ) : (
              <></>
            )}
          </>
        )}
      </Stack>
    </Container>
  );
}
