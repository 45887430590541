import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';

export const SubscriptionPlans = ({
  isCheckout,
  isCheckingOut,
  handleCheckout,
}) => {
  const plans = [
    {
      title: '3-Months',
      price: '$4,999',
      stripePriceId: process.env.REACT_APP_STRIPE_3M_PRICE_ID,
    },
    {
      title: '6-Months',
      price: '$3,999',
      stripePriceId: process.env.REACT_APP_STRIPE_6M_PRICE_ID,
    },
  ];

  return (
    <Grid container spacing={2} sx={{ my: 3, opacity: isCheckingOut ? 0 : 1 }}>
      {plans.map((plan, index) => {
        const onSelect = (e) => {
          e.stopPropagation();
          handleCheckout(plan.stripePriceId);
        };

        return (
          <Grid item xs={12} sm={6} key={index}>
            <Card>
              <CardContent>
                <Typography variant="h5" textAlign={'center'}>
                  {plan.title}
                </Typography>
                <Stack spacing={1} sx={{ mt: 3 }} alignItems={'center'}>
                  <Typography variant="h4" color="primary">
                    {plan.price}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    sx={{ pb: 0.5 }}
                  >
                    per month
                  </Typography>
                </Stack>
              </CardContent>
              {isCheckout && (
                <CardActions sx={{ justifyContent: 'center', mb: 4 }}>
                  <Button size="large" onClick={onSelect} variant="contained">
                    {'Buy this plan'}
                  </Button>
                </CardActions>
              )}
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};
